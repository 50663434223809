@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --animation-speed: 1000ms;
  --animation-speed-fast: 250ms;
  --default-cell-bg-color: theme('colors.white');
  --default-cell-border-color: theme('colors.black');
  --default-cell-text-color: theme('colors.black');
  --absent-cell-bg-color: theme('colors.slate.400');
  --correct-cell-bg-color: theme('colors.green.400');
  --present-cell-bg-color: theme('colors.yellow.400');
}

.dark {
  --default-cell-bg-color: theme('colors.slate.900');
  --default-cell-border-color: theme('colors.white');
  --default-cell-text-color: theme('colors.white');
  --absent-cell-bg-color: theme('colors.slate.700');
}

.high-contrast {
  --correct-cell-bg-color: theme('colors.orange.400');
  --present-cell-bg-color: theme('colors.cyan.400');
}

.cell-fill-animation {
  animation: onTypeCell linear;
  animation-duration: 0.35s;
}

.cell-reveal {
  animation-duration: 0.35s;
  animation-fill-mode: backwards;
}

.cell-reveal.absent {
  animation-name: revealAbsentCharCell;
}

.cell-reveal.correct {
  animation-name: revealCorrectCharCell;
}

.cell-reveal.present {
  animation-name: revealPresentCharCell;
}

.cell-reveal>.letter-container {
  animation: offsetLetterFlip 0.35s linear;
  animation-fill-mode: backwards;
}


svg:hover {
  animation: float infinite;
  animation-duration: var(--animation-speed);
}

.jiggle {
  animation: jiggle linear;
  animation-duration: var(--animation-speed-fast);
}

@keyframes revealAbsentCharCell {

  /* old animation commented by waqas 27/09/2024 */

  /* 0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }

  100% {
    transform: rotateX(180deg);
    background-color: var(--absent-cell-bg-color);
    border-color: var(--absent-cell-bg-color);
  } */

/* new animation written by waqas */

0% {
  transform: translateY(80px);
  opacity: 1; 
  
 
}
100% {
  transform: translateY(0);
  opacity: 1; 
}

}

/* background-color: white!important;
border: 2px solid black!important;
color: black!important; */

/* @keyframes revealAbsentCharCell {
  0% {
    transform: translateY(40px) translateX(0px);
    opacity: 0; 
  }
  100% {
    transform: translateY(0px) translateX(0px);
    opacity: 1; 
  }
} */



@keyframes revealCorrectCharCell {
  /* 0% {
    transform: (0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }

  100% {
    transform: rotateX(180deg);
    background-color: var(--correct-cell-bg-color);
    border-color: var(--correct-cell-bg-color);
  } */
  0% {
    transform: translateY(80px);
    opacity: 1; 
  }
  100% {
    transform: translateY(0);
    opacity: 1; 
  }
}

@keyframes revealPresentCharCell {
  /* 0% {
    transform: rotateX(0deg);
    background-color: var(--default-cell-bg-color);
    border-color: var(--default-cell-border-color);
    color: var(--default-cell-text-color);
  }

  100% {
    transform: rotateX(180deg);
    background-color: var(--present-cell-bg-color);
    border-color: var(--present-cell-bg-color);
  } */

  0% {
    transform: translateY(80px);
    opacity: 1; 
  }
  100% {
    transform: translateY(0);
    opacity: 1; 
  }
}

/* Additional animation on the child div to avoid letters turning upside down/snapping back to upright visual glitch */
/* @keyframes offsetLetterFlip {
  0% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(180deg);
  }
} */

@keyframes onTypeCell {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.shadowed {
  text-shadow: 1px 1px 1px #000000;
}

@keyframes float {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, -0.5rem);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes jiggle {
  0% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(-0.5rem, 0);
  }

  50% {
    transform: translate(0.5rem, 0);
  }

  75% {
    transform: translate(-0.5rem, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

.loader-main {
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  /* background: #0d0d0d45; */
  background: #E5E5EA;


}


/*======================
    404 page
=======================*/

.page_404 {
  /* padding: 40px 0; */
  background: #f6f6f6;
  font-family: 'Arvo', serif;
}

.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #fff !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}

.contant_box_404 {
  margin-top: -50px;
}

.related-article {
  font-weight: 600;
  padding-top: 14px;
  font-size: 21px;
}

.link-color {
  color: blue;
}

.bg-green-500 {
  background: #0B99FF !important;
  border: 1px solid #0B99FF !important;
}
.bg-black-500 {
  background: black !important;
  border: 1px solid black !important;
}

.bg-slate-400 {
  background: #8E8E93 !important;
  border: 1px solid #8E8E93 !important;
}

.bg-yellow-500 {
  background: #FFCD2A !important;
  border: 1px solid #FFCD2A !important;
}

.bg-slate-200 {
  /* background: #d4d6da; */
  background: white;
  font-weight: 600 !important;
  font-size: 0.85rem !important;
  box-shadow: 0px 1px 0px 0px #88888A !important;

}

.bg-slate-200:hover {
  background: #ffffffdb;

}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* background-color: #000; */
  opacity: 0.75;
  z-index: 9999999;
}

.dark p {
  color: white !important;
}

.related-main {
  /* border-top: 1px solid #afafaf;
  padding-top: 4px;
  margin-top: 25px; */
  border-bottom: 1px solid #afafaf;
  padding-top: 4px;
  margin-bottom: 16px;
  padding-bottom: 16px;
}

/* @media (max-width: 900px) {
  .fix-in-bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 1px;
    z-index: 0;
  }
} */

/* .fit-screen {
  position: fixed;
  z-index: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
} */
.disableBtn {
  cursor: not-allowed !important;
}

.image-center {
  text-align: center;
  justify-content: center;
  display: flex;
}

.home-discription {
  font-family: Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 28px;
  line-height: 32.2px;
  color: #000000;
  margin-top: 25px;
  margin-bottom: 45px;
}

@media (max-width: 500px) {
  .home-discription {
    font-size: 23px;
  }
}

.signBtn {
  background: #47a1f4;
  color: white;
  text-decoration: none !important;
  height: 57px;
  width: 267px;

  font-family: Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  line-height: 35.3px;
  color: #ffffff;
  display: block;
  text-align: center;
  border-radius: 10px;
}

.sign-up-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loginLink {
  font-family: Arial;
  font-weight: 400;
  font-style: normal;
  font-size: 22px;
  line-height: 25.3px;
  color: #47a1f4;

  text-align: center;
}

.bottom-text {
  position: absolute;
  left: 50%;
  top: 99%;
  transform: translate(-50%, -99%);
  font-weight: 400;
  font-size: 12px;
  line-height: 13.8px;
  font-family: Arial;
  color: #a7a7a7;
  text-align: center;
}

@media (max-width: 768px) {
  .fix-in-bottom {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 1px;
    z-index: 0;
  }

  .complete-row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 5px;
  }

  .box-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;
  }

  .box-main {
    width: 326px;
    /* height: 390px; */
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    grid-gap: 5px;
    padding: 10px;
    box-sizing: border-box;
  }

  .remove-margin {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
  }

  .fit-on-screens {
    /* font-size: 1.25rem !important; */
    /* width: 3.3rem !important;
    height: 3.1rem !important; */
    animation-delay: 0ms;
    /* width: 100% !important; */
    /* width: 100%; */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    line-height: 2rem;
    font-weight: bold;
    vertical-align: middle;
    box-sizing: border-box;
    text-transform: uppercase;
  }

  /* .h-14 {
    height: 3rem;
  } */
  .fit-on-screens::before {
    content: '';
    display: inline-block;
    padding-bottom: 100%;
  }
}

.padding-on-fit-screen{
  padding :10px 0px 15px 0px;
  
}

/* @media (max-height: 680px){
  .tries-count{
    min-height: 230px !important;
    padding-top: 20px !important;
  }
} */

@media (max-height: 650px) {
  .keyboard-button{
    height: 40px !important;
    font-size: 12px !important;
  }
  .share-btn{
    margin-bottom: 10px !important;
  }
}
@media (max-height: 640px) {
  .tries-count {
      min-height: 200px !important; 
      padding-top: 25px !important;
  }
  .padding-on-fit-screen{
     padding :0px!important; 
    
  }
}
@media (max-height: 620px) {
  .tries-count {
      min-height: 200px !important;
      padding-top: 25px !important;
  }
  .padding-on-fit-screen{
    padding :0px!important;
    
  }
  .tries-count h1{
    font-size: 35px !important;
 }
 .tries-count p{
  font-size: 16px !important;
 }
}
@media (max-height: 611px) {
  .tries-count {
    min-height: 200px !important;
    padding-top: 20px !important;
}
}
@media (max-height: 605px) {
  .tries-count {
    min-height: 200px !important;
    padding-top: 20px !important;
  }
  .tries-count h1{
     font-size: 30px !important;
  }

  .tries-count p{
    padding: 0px 15px;
    line-height: 1.2;
  }
  .padding-on-fit-screen{
    padding :0px!important;
    
  }
  .keyboard-button{
    height: 39px !important;
    /* font-size: 12px !important; */
  }
}


@media (max-height: 580px) {
  .tries-count{
    min-height: 200px !important;
    margin-top:-10px !important;
    /* padding-top: 30px !important; */
  }
  .tries-count h1{
    font-size: 30px !important;
 }
  .padding-on-fit-screen{
    padding :0px!important;
    
  }
  .keyboard-button{
    height: 38px !important;
    font-size: 12px !important;
  }
}


@media (max-height: 580px) {
  
  .tries-count h1{
    font-size: 30px !important;
 }
  .padding-on-fit-screen{
    padding :0px!important;
    
  }
  .keyboard-button{
    height: 38px !important;
    font-size: 12px !important;
  }
}


/* @media (max-height: 660px) and (min-height: 563px) {
  .fit-on-screens {
    font-size: 1.25rem !important;
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
}
@media (max-height: 562px) and (min-height: 515px) {
  .fit-on-screens {
    font-size: 1.25rem !important;
    width: 2rem !important;
    height: 2rem !important;
  }
}

@media (max-height: 514px) and (min-height: 469px) {
  .fit-on-screens {
    font-size: 1.25rem !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
} */

/* @media (max-height: 470px) and (min-height: 420px) {
  .fit-on-screens {
    font-size: 0.7rem !important;
    width: 1rem !important;
    height: 1rem !important;
  }
} */

/* @media (max-height: 690px) and (min-height: 661px) {
  .fit-on-screens {
    font-size: 0.7rem !important;
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
} */

@media (max-width: 600px) {
  .tranformTop {
    transform: translate(0px, -30px) !important;
  }

}

.keyboard-main{
  /* position: fixed; */
  bottom: 0;
  width: 100%; 
  max-width: 450px;

}

.logo-style {
  width: 50px;
  height: 50px;
  border: 1px solid gainsboro;
  border-radius: 50px;
  margin-right: 13px;
}

/* @media (max-width: 600px) {
  .remove-margin {
    margin-top: 1rem !important;
  }
} */
.top-head-outline {
  position: relative;
  background: rgba(255, 255, 255, 1);
  border: 0.75px solid rgba(209, 209, 209, 1);
}
 .sponsorship-module {
  border: 0.75px solid rgba(209, 209, 209, 1);
  background: linear-gradient(0deg, #F9F9F9 0%, #FFFFFF 100%);
}


.sponsorship-module-logo {
  height: 40px;width: 40px;  
}
.sponsorship-module-inner{
  display: flex;
  align-items: center;
  padding: 4.5px 0px;
  justify-content: center;
}

.sponsorship-text{
  padding: 4px 8px;
  line-height: 1;
  font-size: clamp(12px, 3.5vw, 16.5px);
  font-weight: 400;

}
.hyperlink{
  text-decoration: none;
  color: rgba(0, 0, 0, 1);
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
  
}

@media (max-width : 451px){
  .hyperlink{
    margin-left: 7%;
    margin-right: 7%;
  }
}
@media (max-width : 356px){
  .hyperlink{
    margin-left: 6%;
    margin-right: 6%;
  }
}
@media (max-width : 337px){
  .hyperlink{
    margin-left: 5%;
    margin-right: 5%;
  }
}
@media (max-width : 330px){
  .hyperlink{
    margin-left: 5%;
    margin-right: 4%;
  }
}
@media (max-width : 327px){
  .hyperlink{
    margin-left: 4%;
    margin-right: 2%;
  }
}
/* .top-brand-name {
  font-size: 26px;
  padding-bottom: 3px;
} */

.top-brand-name {
  /* font-size: 23px; */
  font-weight: 800;
  font-size: clamp(18px, 3.5vw, 23px);
  padding:9px 0px;
  display: flex;
  align-items: center;
}
.top-brand-name > img{
  height: 40px;
  padding-right: 8px;
}
.top-client-name{
  font-size: clamp(17px, 3.5vw, 23px);
  font-weight: 400;
  padding-left: 4px;
}
.how-to-play-icon {
  /* width: calc(12px + 1vw);
  height: calc(12px + 1vw); */
  cursor: pointer;
  height: 23px;
    width: 23px;
}

.top-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 18px;
}

.keyboard-style {
  background: #D0D3DA;
  padding: 15px;

}

.keyboard-style>div:last-child>button:first-child,
.keyboard-style>div:last-child>button:last-child {
  background: #A9AFBB !important;
  border: 1px solid #A9AFBB !important;
  font-size: 12px !important;
}

.keyboard-style > div:last-child > button:first-child:active,
.keyboard-style > div:last-child > button:last-child:active {
  background: #8E8E93 !important;
  border: 1px solid #8E8E93 !important;
}

.main-content {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 450px;
}


/* .space-between-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 44px);
} */

.tries-count-body {
  padding: 20px;
  padding-bottom: 0PX;
}

.tries-count {
  background: #E7F5FF;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* min-height: 285px; */
  /* height: calc(100vh - 465px); */
}

.current-guess-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.row-margin-remove>div:first-child {
  margin-bottom: 0px !important;
}

.row-margin-remove {
  background: #E7F5FF !important;
  padding: 12px 0px !important;
}

.text_lg {
  font-size: 1.5rem !important;
  text-align: left !important;
  font-weight: bold !important;
}

@media (max-width: 370px) {
  .text_lg {
    font-size: 1.3rem !important;
  }
}

.mt-2 {
  text-align: left !important;
  font-size: 17px !important;
}

.border_bottom {
  border-bottom: 0.75px solid #A9AFBB;
  margin-bottom: 10px;
}

.got_it_btn {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.got_it_btn button {
  border: 1px solid #000000;
  border-radius: 50px;
  text-align: center;
  height: 38px;
  width: 200px;
  outline: none !important;

}

.no-puzzle {
  max-width: 22rem;
  width: 98%;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0);
  border-radius: 1rem;
  padding-top: 1.8rem;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
  margin-bottom: 1.2rem;

}

.get-started-logo img {
  width: 10.125rem;
  height: 10.125rem;
  margin-bottom: 2rem;
}

.no-puzzle h5 {
  font-size: 16px !important;
}

.suggetion {
  padding: 0px 20px;
  line-height: 0;
  width: 300px;
  position: relative;
  bottom: -10px;
  margin: 0 auto;
}

.suggetion-shape img {
  width: 40px;
  height: 20px;
}

.suggetion-shape {
  display: flex;
  justify-content: center;
}

.suggetion-inner {
  background: white;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  box-shadow: 2px 2px 12px 0px #0000001A;
}

.statistics-row>div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.statistics-row {
  background: #E8F3FF;
  border-radius: 9px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px 0px;

}

.result-heading {
  font-size: 1rem;
  font-weight: bold;
}

.backGroundColor {
  background: #E8F3FF;
  border-radius: 9px;
  justify-content: space-between;
}

.p-two {
  padding: 15px;
}

.pp-two {
  padding: 15px;
}

.min_height {
  min-height: 155px;
}

.pg-br-w-h {
  width: 2.5rem;
  color: white;
  font-weight: bold;
  max-height: 105px;
}

@media(max-width:346px) {
  .pg-br-w-h {
    width: 2.3rem;
  }

  .pp-two {
    padding: 12px;
  }

}

@media(max-width:460px) {
  .top-head {
    padding: 0px 10px;
  }
}

.pg-br {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  line-height: 2;
  color: black;
}

.puzzle-headline p {
  font-weight: 600;
  line-height: 1.25;
  font-size: 20px;
  
}

.puzzle-headline {
  background: #E8F3FF;
  border-radius: 9px;
  margin-bottom: 20px;

}

.read-article  {
  text-decoration: none;
  color: white !important;
  font-weight: 500;
  background: #0B99FF;
  max-width: 245px;
  display: flex;
  justify-content: center;
  padding: 6px;
  border-radius: 50px;
  font-size: 16px;
  outline: none;
  margin: 20px auto;
  cursor: pointer;
}
.signup-itsfree {
  text-decoration: none;
  color: white !important;
  font-weight: 500;
  background: #0B99FF;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 9px 6px;
  border-radius: 50px;
  font-size: 16px;
  outline: none;
  margin: 14px 0;
  cursor: pointer;
}
.Maybe-later , .No-thanks{
  background: white!important;
  color: black !important;
  border: solid 1px rgba(142, 142, 147, 1);
}
.model-bottom-text{
  font-size: clamp(10px, 1.5vw, 12px);
  text-align: center;
  color: rgba(169, 175, 187, 1);
}

.share-btn {
  font-size: 15px !important;
  text-align: center !important;
  background: #E5E5EA !important;
  border: 1px solid #E5E5EA !important;
  color: black !important;
  margin-bottom: 10px !important;
}

.bottom-counter {
  display: flex;
  justify-content: space-around;
  font-size: 16px;
}

.bottom-counter h6 {
  font-size: 16px !important;
  font-weight: 600 !important;
  padding-top: 5px !important;
}

.result_page_main {
  max-width: 450px;
  margin: 0 auto;
  padding: 20px 16px 0px 16px;
}

.startup_page_main {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* min-height: calc(100vh - 44px); */
  background: #E7F5FF;
  overflow: hidden;
}

.mx-width {
  max-width: 450px;
  margin: 0 auto;
}

.welcome-text {
  /* font-family: Inter; */
  font-size: 45px;
  font-weight: 700;
  /* line-height: 78.66px; */
  text-align: center;
  color: #3C3C3C;

}

.welcome-heading {

  font-weight: 500;
  line-height: 28.31px;
  text-align: center;
  max-width: 351.4px;
}

.spacer {
  clear: both;
  height: 35px;
}

.play-button {
  text-align: center;
  display: flex;
  justify-content: center;
}

.play-btn {
  width: 250px;
  height: 40px;
  border-radius: 36.5px !important;
  /* font-family: Inter; */
  /* font-size: 20px; */
  font-weight: 500 !important;
  /* line-height: 36.31px; */
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.welcome-heading-text p {
  padding: 0px 15px;
  line-height: 1.2;
  font-size: 21px;
  margin: 0;
  color: #3C3C3C;

}

.customer-name p {
  font-size: 23px;
  color: #3C3C3C;
}

.inner-shadow{
  box-shadow: 2px 2px 8px 0px #0000001A inset;

}
.guess_histroy{
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  line-height: 29.05px;
  color: #3C3C3C;
  margin-bottom: 15px;
}

.prestatmodel{
  width: 283px;
  height: 176px;
  border-radius: 5px 0px 0px 0px;
  background: #FFFFFF;
  /* box-shadow: -5px 5px 12px 0px #00000014; */
  box-shadow: -5px 5px 12px 0px #00000014, 5px 0px 12px 0px #00000014;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  position: absolute;
  top: 45px;
  right: 0;
  z-index: 1;

}

.prestat-bottom-text {
  
  
  font-weight: 400;
  font-size: 12px;
  line-height: 13.8px;
  font-family: Arial;
  color: #88888A;
  text-align: center;
}
.preguess-text{
  font-size: 22px;
  font-weight: 400;
  line-height: 33.89px;
  text-align: left;
  color: #88888A;

}
.current-guess-row-new{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.gray-text{
    font-size: 18px !important;
    font-weight: 400!important;
    line-height: 21.78px!important;
    text-align:left!important;
    margin-bottom:  25px !important;
}
.root-url{
  font-size: 18px;
    font-weight: 800;
    line-height: 21.78px;
    text-align: left;
    margin-top: 10px;
    margin-bottom:10px;
}
.article-headline {
  font-size: 22px !important;
  font-weight: 600 !important;
  line-height: 28px !important;
  text-align: left!important;
}
.is-embed{
  display: none!important;
}
.notsigned-up-yet{
  border-top: 1px solid rgba(208, 211, 218, 1);
  padding-top:20px;
  margin-top: 10px;
  text-align: center;
}
  .notsigned-text{
  font-size: 24px;
  font-weight: 700;
  line-height: 33.89px;
  text-align: center;
  }
.notsigned-innertext{
  font-size: 18px;
  font-weight: 400;
  line-height: 22.63px;
  text-align: center;
  padding-top: 5px;
}
.model-description{
  padding-bottom: 20px;
  line-height: 1.2;

}
.parent {
  width: 100%;
  display: flex;
  justify-content: center; /* Center the child div horizontally in the parent */
}

.child {
  max-width: 450px; /* Limit the width */
  width: 100%;
  margin: 0 auto;
}

.child a {
  display: flex;
  align-items: center; /* Center image and text vertically */
  justify-content: center; /* Center the content */
  gap: 3px; /* Add spacing between image and text */
  text-decoration: none; /* Remove underline */
}

.child a img {
  width: 40px; /* Image width */
  height: auto; /* Maintain image aspect ratio */
  display: block;
}

.child a p {
  display: inline-block; /* Make the <p> shrink to fit its content */
  margin: 0; /* Remove default margin */
  padding: 4px 8px;
  line-height: 1;
  font-size: clamp(12px, 3.5vw, 16.5px);
  font-weight: 400;
  white-space: normal; /* Allow text to break to the next line */
}

/* padding: 4px 8px;
line-height: 1;
font-size: clamp(12px, 3.5vw, 16.5px);
font-weight: 400; */
.ads-dev{   
    text-align: center;
    background: white;
    bottom: 0;
    text-align: -webkit-center;
    /* position: absolute;
    bottom: 0;
    left: 0 ;
    right: 0; */
    max-height: 50px;
    
}
.ads-dev > img{
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
}

.desk-ads{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 285px;
  text-align: '-webkit-center';
}
.desk-ads-text{
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: rgba(169, 175, 187, 1);
}
.ad-text{
font-size: 12px;
font-weight: 500;
letter-spacing: 0.03em;
text-align: center;
color: rgba(169, 175, 187, 1);
position: absolute;
top: 12px;
}
.ad-head{
  display: flex;
  justify-content: end;
  align-items: center;
  line-height: 1;
  flex-direction: column;
  padding: 10px 0px;
  height: 90px;
  position: relative;
  /* background: darkcyan; */
}